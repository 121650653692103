<template>
  <div class="upgradePrice">
     <iframe scrolling: no
      :src="src"
      frameborder="0"
    ></iframe>
  </div>
</template>
<script>
import urls from "../../../utils/baseUrl2";
export default {
  name: "upgradePrice",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      src: `${urls.baseUrl1}upgradePrice/roomPriceIframe.html?upgradeId=${this.id}&isDevelopment=${urls.baseUrl}`,
    };
  },
  created() {
    console.log(urls.baseUrl1, "路径");
  },
};
</script>
<style lang="less" scoped>
.upgradePrice {
  height: 860px;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>